import axios, { Method } from "axios"
import ApiError from "./api-error"

const apiService = async (
  endpoint: string,
  args: {
    method?: Method
    token?: string
    data?: any
    params?: any
  } = {
    method: "GET",
    data: {},
    params: {},
  },
) => {
  const { token } = args

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "",
    },
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  try {
    const { data } = await axios({
      url: `${process.env.NEXT_PUBLIC_API_URL || ""}/${endpoint}`,
      ...config,
      ...args,
    })

    return data
  } catch (error_: any) {
    const error = new ApiError(error_.response.data.message || "Server Error")
    error.status = error_.response.status
    error.message = error_.response.data.message || "Server Error"
    error.errors = error_.response.data.errors || null
    error.response = error_.response.data || null
    throw error
  }
}

const API = {
  services: {
    cart: {
      get: async (token: string) => {
        return apiService(`cart`, {
          params: { token },
        })
      },
      add: async (
        token: string,
        data: { product_id: number; quantity: number; quantity_unit?: string },
      ) => {
        return apiService(`cart/create`, {
          method: "POST",
          data,
          params: { token },
        })
      },
      update: async (token: string, data: { id: number; quantity: number }) => {
        return apiService(`cart/update`, {
          method: "PUT",
          data,
          params: { token },
        })
      },
      delete: async (token: string, id: number) => {
        return apiService(`cart/delete`, {
          method: "DELETE",
          data: { id },
          params: { token },
        })
      },
      checkout: async (
        token: string,
        data: {
          shipping_address_id: string
          special_instructions: string
          reference_number: string
        },
      ) => {
        return apiService(`cart/checkout`, {
          method: "POST",
          data,
          params: { token },
        })
      },
    },
    auth: {
      login: async (data: { login: string; password: string }) => {
        return apiService("auth/login", {
          method: "POST",
          data,
        })
      },
      refreshToken: async (token: string) => {
        return apiService(`auth/refresh?token=${token}`, {
          method: "POST",
        })
      },
      requestPasswordReset: async (data: { login: string }) => {
        return apiService(`auth/password/request`, {
          method: "POST",
          data,
        })
      },
      resetPassword: async (data: {
        email: string
        token: string
        password: string
        password_confirmation: string
      }) => {
        return apiService(`auth/password/confirm`, {
          method: "POST",
          data,
        })
      },
    },
    orders: {
      index: async (token: string, page: number) => {
        return apiService(`user/orders`, {
          params: {
            token,
            page,
          },
        })
      },
      single: async (token: string, id: string) => {
        return apiService(`user/orders/${id}`, {
          params: { token },
        })
      },
    },
    user: {
      info: async (token: string) => {
        return apiService(`user/show`, {
          params: {
            token,
          },
        })
      },
      updatePassword: async (
        token: string,
        data: {
          password: string
          new_password: string
          new_password_confirmation: string
        },
      ) => {
        return apiService(`user/password`, {
          method: "PUT",
          data,
          params: { token },
        })
      },
      addresses: async (token: string) => {
        return apiService(`user/addresses`, {
          params: {
            token,
          },
        })
      },
      setDefaultAddress: async (token: string, id: string) => {
        return apiService(`user/addresses/update`, {
          method: "POST",
          data: {
            address_id: id,
          },
          params: {
            token,
          },
        })
      },
      reorder: async (
        token: string,
        data: {
          id: number
        },
      ) => {
        return apiService(`user/reorder`, {
          method: "POST",
          data,
          params: { token },
        })
      },
    },
    index: async ({
      type,
      main_category_id,
    }: {
      type: string
      main_category_id: number
    }) => {
      return apiService("index", {
        params: {
          type,
          main_category_id,
        },
      })
    },
    products: {
      index: async ({
        perPage = 39,
        page = 1,
        ...data
      }: {
        page?: number
        main_category_id?: number
        sub_categories?: number[]
        component_type?: string
        fabric_type?: string
        item_numbers?: string[]
        perPage?: number
      }) => {
        return apiService("products", {
          params: {
            perPage,
            page,
            ...data,
          },
        })
      },
      search: async (query: string, page = 1) => {
        return apiService("search", {
          params: {
            search_term: query,
            page,
          },
        })
      },
      single: async (slug: string) => {
        return apiService(`products/${slug}`)
      },
      recommendations: async (slug: string) => {
        return apiService(`products/${slug}/recommendations`)
      },
      recentlyOrdered: async (token: string) => {
        return apiService(`products/recently-ordered`, { params: { token } })
      },
      rate: async (
        token: string,
        slug: string,
        data: { rating: number; comment?: string },
      ) => {
        return apiService(`products/${slug}/rate`, {
          method: "POST",
          data,
          params: {
            token,
          },
        })
      },
    },
    categories: {
      index: async () => {
        return apiService("categories")
      },
      single: async (slug: string) => {
        return apiService(`categories/${slug}`)
      },
      subCategory: async (categorySlug: string, subCategorySlug: string) => {
        return apiService(
          `categories/${categorySlug}/subcategories/${subCategorySlug}`,
        )
      },
    },
  },
}

export default API
